<template>
  <div class="" data-aos="zoom-in" data-aos-duration="1500" v-if="contactTexte">
    <div class=" h-100 w-100">
      <div class="background-color-body d-flex justify-content-between pt-5" v-if="apiUp == true">
        <div style="flex-basis: 40%; text-align: center">
          <h1>{{contactTexte.titre}}</h1>
          <hr />
        </div>
        <div style="flex-basis: 60%; position: relative">
          <form v-if="!estEnvoye" @submit.prevent="envoyer()" class="m-3">
            <div class="d-flex align-items-start">
              <div class="mr-3" style="flex-basis:35%">
                <div class="form-group">
                  <label>{{contactTexte.fieldset?.titreNomPrenom}} <span class="text-danger">*</span></label>
                  <input
                    v-model="nomPrenom"
                    type="text"
                    autocomplete="on"
                    :placeholder="contactTexte.fieldset?.placeholderNomPrenom"
                    class="form-control form-control-lg"
                    required
                  />

                  <i class="fas fa-user"></i>
                </div>
                <div class="form-group">
                  <label>{{contactTexte.fieldset?.titreSujet}} <span class="text-danger">*</span></label>
                  <input
                    v-model="sujet"
                    type="text"
                    autocomplete="on"
                    :placeholder="contactTexte.fieldset?.placeholderSujet"
                    class="form-control form-control-lg"
                    required
                  />

                  <i class="fas fa-mobile"></i>
                </div>
                <div class="form-group">
                  <label
                    >{{contactTexte.fieldset?.titreEmail}}
                    <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="email"
                    type="email"
                    autocomplete="on"
                    :placeholder="contactTexte.fieldset?.placeholderEmail"
                    class="form-control form-control-lg"
                    required
                  />

                  <i class="fas fa-envelope"></i>
                </div>
              </div>

              <div class="flex-grow-1 mr-5" style="flex-basis:65%">
                <div class="form-group">
                  <label>{{contactTexte.fieldset?.titreMessage}} <span class="text-danger">*</span></label>
                  <textarea
                    v-model="message"
                    :placeholder="contactTexte.fieldset?.placeholderMessage"
                    class="form-control form-control-lg"
                    rows="7"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="mt-2 hidden">
              <label for="exampleFormControlFile1">{{contactTexte.fieldset?.titreFichier}}</label>
              <input
                type="file"
                ref="file"
                @change="onChange($event)"
                class="form-control-file form-control-lg"
              />
            </div>
            <div class="mt-3" align="center">
              <button class="btn btn-success" type="submit">
                {{contactTexte.fieldset?.messageEnvoyer}}
              </button>
            </div>
          </form>
          <div
            v-else
            class="d-flex justify-content-center mt-5"
            style="text-align: center"
          >
            <div v-if="!refresh">
              <div class="mb-3">
                <span class="fa fa-4x fa-envelope-open-text mr-4"></span>
                <span class="fas fa-4x fa-arrow-right-long mr-4"></span>
                <span class="fa fa-4x fa-envelope"></span>
              </div>
              <span class="text-success" style="font-size: 140%"
                >{{contactTexte.fieldset?.messageEnvoye}} <span class="fa fa-check"></span
              ></span>
            </div>
            <div v-else>
              <i class="fas fa-5x fa-spinner fa-pulse"></i>
            </div>
          </div>
          <!-- <div style="width:99%" class=" h-100 d-flex align-items-center justify-content-center module-en-creation">
          <span>En cours de création</span>
        </div> -->
        </div>
      </div>
      <div
        v-else
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          h-100
        "
      >
        <b style="font-size: 260%" class="text-center "
          >{{contactTexte.fieldset?.autreMessage}} !</b
        >
        <div
          style="font-size: 200%"
          class="d-flex flex-row align-items-center mt-5 card size-100-tel"
        >
          <span class="span-email" style="position: relative">
            <div
              class="mr-3 p-2 cursor-pointer"
              style="display: inline"
              @click="
                () => {
                  copier('beaudthomas@gmail.com');
                  changerText();
                }
              "
            >
              beaudthomas@gmail.com
            </div>
            <span
              class="title-email"
              id="title-email"
              style="position: absolute; font-size: 70%"
            >
              {{contactTexte.fieldset?.MessageCopier}}</span
            >
          </span>
          <a
            href="mailto:beaudthomas@gmail.com"
            @click="copier('beaudthomas@gmail.com')"
            class="p-2 card enveloppe background-color-body"
            ><span class="fa fa-envelope"></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='./contact.js'></script>

<style scoped>
.span-email .title-email,
.span-email:hover .title-email:hover {
  visibility: hidden;
}
.span-email:hover .title-email {
  visibility: visible;
  transition: all 0.18s ease-out 0.18s;
}
.title-email {
  top: 45px;
  right: 30%;
  color: #fff;
  background: rgba(33, 37, 41, 0.9);
  width: 40%;
  text-align: center;
  z-index: 100;
}
hr {
  width: 10%;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 300%;
}

.form-group {
  position: relative;
  margin-top: 20px;
}

.form-group i {
  position: absolute; /* positionné par rapport à son parent le plus proche positionné */
  left: 0;
  top: 25px;
  padding: 15px 8px;
  color: #747cdf;
}

.form-group input {
  padding: 10px 5px 10px 30px;
  border: 1px solid #c9c9c9;
  outline-color: #747cdf;
  border-radius: 5px;
}

label {
  font-weight: bold;
  margin-bottom: 0;
}

.enveloppe {
  color: white;
  background-color: #3abfc3 !important;
}

@media (max-width: 600px) {
  .d-flex {
    flex-direction: column;
  }
  .pt-5 {
    padding-top: 0.5rem !important;
  }
  form div {
    align-items: center !important;
  }
}
</style>